var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "ml-4 mt-2", attrs: { cols: "2" } },
                [
                  _c("v-img", {
                    staticClass: "navbar-brand-img ms-3",
                    attrs: {
                      src: require("@/assets/img/logoSOV.png"),
                      height: "50px",
                      width: "120px",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "3" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "" } },
                    [
                      _c("v-toolbar-title", [
                        _vm._v(_vm._s(this.siteGroupName) + " Fleet Report"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "3" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "" } },
                    [
                      _c("v-toolbar-title", { staticClass: "text-h5" }, [
                        _c("b", { staticClass: "text-h5 font-weight-bold" }, [
                          _vm._v("Q" + _vm._s(this.previousQuarter)),
                        ]),
                        _vm._v(" - (" + _vm._s(this.displayDates) + ")"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "v-tabs",
                {
                  model: {
                    value: _vm.NewTab,
                    callback: function ($$v) {
                      _vm.NewTab = $$v
                    },
                    expression: "NewTab",
                  },
                },
                [
                  _c("v-tab", { attrs: { href: "#chart_tab" } }, [
                    _vm._v(" Fleet Report "),
                  ]),
                  _c("v-tab", { attrs: { href: "#fleet_stats" } }, [
                    _vm._v(" Individual Sites "),
                  ]),
                  _c("v-tab", { attrs: { href: "#inverter_stats" } }, [
                    _vm._v(" Inverter Stats "),
                  ]),
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.NewTab,
                    callback: function ($$v) {
                      _vm.NewTab = $$v
                    },
                    expression: "NewTab",
                  },
                },
                [
                  _c(
                    "v-tab-item",
                    { attrs: { value: "fleet_stats" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass:
                                    "card-shadow border-radius-xl h-100",
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c("v-data-table", {
                                        ref: "datatable",
                                        staticClass: "table",
                                        attrs: {
                                          headers: _vm.quarterHeaders,
                                          items: _vm.sites,
                                          page: _vm.page,
                                          "hide-default-footer": "",
                                          "items-per-page": _vm.itemsPerPage,
                                        },
                                        on: {
                                          "current-items": _vm.getFiltered,
                                          "update:page": function ($event) {
                                            _vm.page = $event
                                          },
                                          "page-count": function ($event) {
                                            _vm.pageCount = $event
                                          },
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "top",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "v-toolbar",
                                                  { attrs: { flat: "" } },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass: "mt-12",
                                                        on: {
                                                          click:
                                                            _vm.exportToCsv,
                                                        },
                                                      },
                                                      [_vm._v(" Export ")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "header.q_health",
                                            fn: function (ref) {
                                              var header = ref.header
                                              return [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { top: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "span",
                                                                _vm._g({}, on),
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      header.text
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "This is based on satellite imagery to estimate production based on the actual sunshine that fell on your array. Snow cover influences this significantly."
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "header.q_projected",
                                            fn: function (ref) {
                                              var header = ref.header
                                              return [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { top: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "span",
                                                                _vm._g({}, on),
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      header.text
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "This is based on 30 year historical averages from PVWatts or customer provided monthly projections. Wide variation is normal if this period was far from average."
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "header.notes",
                                            fn: function (ref) {
                                              var header = ref.header
                                              return [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { top: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "span",
                                                                _vm._g({}, on),
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      header.text
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Cases open for this site at any point in this quarter."
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "header.year_health",
                                            fn: function (ref) {
                                              var header = ref.header
                                              return [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { top: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "span",
                                                                _vm._g({}, on),
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      header.text
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "This is based on satellite imagery to estimate production based on the actual sunshine that fell on your array. Snow cover influences this significantly."
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "header.year_projected",
                                            fn: function (ref) {
                                              var header = ref.header
                                              return [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { top: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "span",
                                                                _vm._g({}, on),
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      header.text
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "This is based on 30 year historical averages from PVWatts or customer provided monthly projections. Wide variation is normal if this period was far from average."
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.site_name",
                                            fn: function (ref) {
                                              var item = ref.item
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex align-center ms-2",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openWindow(
                                                          item.url
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-base font-weight-bold text-body",
                                                        staticStyle: {
                                                          cursor: "pointer",
                                                          color: "blue",
                                                          "text-decoration":
                                                            "underline",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.site_name
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.q_production",
                                            fn: function (ref) {
                                              var item = ref.item
                                              return [
                                                item.q_production
                                                  ? _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.q_production.toLocaleString(
                                                            "en-US"
                                                          )
                                                        )
                                                      ),
                                                    ])
                                                  : _c("div", [_vm._v("0")]),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.q_health",
                                            fn: function (ref) {
                                              var item = ref.item
                                              return [
                                                item.q_health
                                                  ? _c(
                                                      "v-progress-linear",
                                                      {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          value: item.q_health,
                                                          height: "15",
                                                          "background-color":
                                                            _vm.progressBackground(
                                                              item.q_health
                                                            ),
                                                          color:
                                                            _vm.progressBarColor(
                                                              item.q_health
                                                            ),
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-center",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                Math.round(
                                                                  item.q_health
                                                                ).toFixed(0)
                                                              ) + "%"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "v-progress-linear",
                                                      {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          value: 0,
                                                          height: "15",
                                                          "background-color":
                                                            _vm.progressBackground(
                                                              0
                                                            ),
                                                          color:
                                                            _vm.progressBarColor(
                                                              0
                                                            ),
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-center",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(0) + "%"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.q_projected",
                                            fn: function (ref) {
                                              var item = ref.item
                                              return [
                                                item.q_projected
                                                  ? _c(
                                                      "v-chip",
                                                      {
                                                        attrs: {
                                                          color: _vm.getColor(
                                                            item.q_projected
                                                          ),
                                                          dark: "",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              Math.round(
                                                                item.q_projected
                                                              ).toFixed(0)
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "v-chip",
                                                      {
                                                        attrs: {
                                                          color:
                                                            _vm.getColor(0),
                                                          dark: "",
                                                        },
                                                      },
                                                      [_vm._v(" 0 ")]
                                                    ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.q_downtime",
                                            fn: function (ref) {
                                              var item = ref.item
                                              return [
                                                _c(
                                                  "v-chip",
                                                  {
                                                    attrs: {
                                                      color:
                                                        _vm.transformDowntime(
                                                          item.q_downtime
                                                        ),
                                                      dark: "",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          Math.round(
                                                            item.q_downtime
                                                          ).toFixed(0)
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.notes",
                                            fn: function (ref) {
                                              var item = ref.item
                                              return [
                                                _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.notes) +
                                                      " "
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.year_production",
                                            fn: function (ref) {
                                              var item = ref.item
                                              return [
                                                item.year_production
                                                  ? _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.year_production.toLocaleString(
                                                            "en-US"
                                                          )
                                                        )
                                                      ),
                                                    ])
                                                  : _c("div", [_vm._v("0")]),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.year_health",
                                            fn: function (ref) {
                                              var item = ref.item
                                              return [
                                                item.year_health
                                                  ? _c(
                                                      "v-progress-linear",
                                                      {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          value:
                                                            item.year_health,
                                                          height: "15",
                                                          "background-color":
                                                            _vm.progressBackground(
                                                              item.year_health
                                                            ),
                                                          color:
                                                            _vm.progressBarColor(
                                                              item.year_health
                                                            ),
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-center",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                Math.round(
                                                                  item.year_health
                                                                ).toFixed(0)
                                                              ) + "%"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "v-progress-linear",
                                                      {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          value: 0,
                                                          height: "15",
                                                          "background-color":
                                                            _vm.progressBackground(
                                                              0
                                                            ),
                                                          color:
                                                            _vm.progressBarColor(
                                                              0
                                                            ),
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-center",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(0) + "%"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.year_projected",
                                            fn: function (ref) {
                                              var item = ref.item
                                              return [
                                                item.year_projected
                                                  ? _c(
                                                      "v-chip",
                                                      {
                                                        attrs: {
                                                          color: _vm.getColor(
                                                            item.year_projected
                                                          ),
                                                          dark: "",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              Math.round(
                                                                item.year_projected
                                                              ).toFixed(0)
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "v-chip",
                                                      {
                                                        attrs: {
                                                          color:
                                                            _vm.getColor(0),
                                                          dark: "",
                                                        },
                                                      },
                                                      [_vm._v(" 0 ")]
                                                    ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-actions",
                                    { staticClass: "card-padding" },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "d-flex align-center",
                                              attrs: { cols: "6", lg: "3" },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-body me-3 text-sm",
                                                },
                                                [_vm._v("Items per page:")]
                                              ),
                                              _c("v-text-field", {
                                                staticClass:
                                                  "font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon",
                                                attrs: {
                                                  "hide-details": "",
                                                  type: "number",
                                                  outlined: "",
                                                  min: "-1",
                                                  max: "15",
                                                  "background-color":
                                                    "rgba(255,255,255,.9)",
                                                  color: "rgba(0,0,0,.6)",
                                                  light: "",
                                                  value: _vm.itemsPerPage,
                                                  placeholder: "Items per page",
                                                },
                                                on: {
                                                  input: _vm.updateItemsPerPage,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "ml-auto d-flex justify-end",
                                              attrs: { cols: "6" },
                                            },
                                            [
                                              _c("v-pagination", {
                                                staticClass: "pagination",
                                                attrs: {
                                                  "prev-icon":
                                                    "fa fa-angle-left",
                                                  "next-icon":
                                                    "fa fa-angle-right",
                                                  color: "#D81B60",
                                                  length: _vm.pageCount,
                                                  circle: "",
                                                },
                                                model: {
                                                  value: _vm.page,
                                                  callback: function ($$v) {
                                                    _vm.page = $$v
                                                  },
                                                  expression: "page",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { attrs: { value: "inverter_stats" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "v-card",
                                { staticClass: "mt-4" },
                                [
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass:
                                        "text-subtitle-2 font-weight-bold",
                                    },
                                    [
                                      _vm._v(
                                        " This section displays each inverter's production (adjusted for size) compared to the median production of the other inverters in that specific site for each month of the current quarter. "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-card",
                                { staticClass: "mt-n8" },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c("v-data-table", {
                                        ref: "datatable",
                                        staticClass: "table",
                                        attrs: {
                                          headers: _vm.inverterHeaders,
                                          items: _vm.sites,
                                          "single-expand": _vm.singleExpand,
                                          expanded: _vm.expandedIds,
                                          "item-key": "id",
                                          "show-expand": "",
                                          "hide-default-footer": "",
                                          page: _vm.page2,
                                          "items-per-page": _vm.itemsPerPage2,
                                          "mobile-breakpoint": "0",
                                        },
                                        on: {
                                          "update:expanded": function ($event) {
                                            _vm.expandedIds = $event
                                          },
                                          "current-items": _vm.getFiltered2,
                                          "update:page": function ($event) {
                                            _vm.page2 = $event
                                          },
                                          "page-count": function ($event) {
                                            _vm.pageCount2 = $event
                                          },
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "top",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "v-toolbar",
                                                  { attrs: { flat: "" } },
                                                  [
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.expandAll()
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Expand All"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "expanded-item",
                                            fn: function (ref) {
                                              var headers = ref.headers
                                              var item = ref.item
                                              return [
                                                _c(
                                                  "td",
                                                  {
                                                    attrs: {
                                                      colspan: headers.length,
                                                    },
                                                  },
                                                  [
                                                    _c("fleet-inverters", {
                                                      attrs: {
                                                        site_name:
                                                          item.site_name,
                                                        inverterMetrics:
                                                          _vm.inverter_metrics[
                                                            item.site_name
                                                          ],
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-actions",
                                    { staticClass: "card-padding" },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "d-flex align-center",
                                              attrs: { cols: "6", lg: "3" },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-body me-3 text-sm",
                                                },
                                                [_vm._v("Items per page:")]
                                              ),
                                              _c("v-text-field", {
                                                staticClass:
                                                  "font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon",
                                                attrs: {
                                                  "hide-details": "",
                                                  type: "number",
                                                  outlined: "",
                                                  min: "-1",
                                                  max: "15",
                                                  "background-color":
                                                    "rgba(255,255,255,.9)",
                                                  color: "rgba(0,0,0,.6)",
                                                  light: "",
                                                  value: _vm.itemsPerPage2,
                                                  placeholder: "Items per page",
                                                },
                                                on: {
                                                  input:
                                                    _vm.updateItemsPerPage2,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "ml-auto d-flex justify-end",
                                              attrs: { cols: "6" },
                                            },
                                            [
                                              _c("v-pagination", {
                                                staticClass: "pagination",
                                                attrs: {
                                                  "prev-icon":
                                                    "fa fa-angle-left",
                                                  "next-icon":
                                                    "fa fa-angle-right",
                                                  color: "#D81B60",
                                                  length: _vm.pageCount2,
                                                  circle: "",
                                                },
                                                model: {
                                                  value: _vm.page2,
                                                  callback: function ($$v) {
                                                    _vm.page2 = $$v
                                                  },
                                                  expression: "page2",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { attrs: { value: "chart_tab" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass:
                                    "card-shadow border-radius-xl h-100",
                                },
                                [
                                  _c(
                                    "div",
                                    { attrs: { id: "quarterly-stats" } },
                                    [
                                      _c("apexchart", {
                                        attrs: {
                                          height: "400",
                                          type: "line",
                                          options: _vm.quarterlyOptions,
                                          series: _vm.series,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass:
                                    "card-shadow border-radius-xl h-100",
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("v-data-table", {
                                        ref: "datatable",
                                        staticClass: "table",
                                        attrs: {
                                          headers: _vm.summaryHeaders,
                                          items: _vm.summary,
                                          "hide-default-footer": "",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "header.q_health",
                                            fn: function (ref) {
                                              var header = ref.header
                                              return [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { top: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "span",
                                                                _vm._g({}, on),
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      header.text
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "This is based on satellite imagery to estimate production based on the actual sunshine that fell on your array. Snow cover influences this significantly."
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "header.q_projected",
                                            fn: function (ref) {
                                              var header = ref.header
                                              return [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { top: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "span",
                                                                _vm._g({}, on),
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      header.text
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "This is based on 30 year historical averages from PVWatts or customer provided monthly projections. Wide variation is normal if this period was far from average."
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "header.year_health",
                                            fn: function (ref) {
                                              var header = ref.header
                                              return [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { top: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "span",
                                                                _vm._g({}, on),
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      header.text
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "This is based on satellite imagery to estimate production based on the actual sunshine that fell on your array. Snow cover influences this significantly."
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "header.year_projected",
                                            fn: function (ref) {
                                              var header = ref.header
                                              return [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { top: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "span",
                                                                _vm._g({}, on),
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      header.text
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "This is based on 30 year historical averages from PVWatts or customer provided monthly projections. Wide variation is normal if this period was far from average."
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.site_name",
                                            fn: function (ref) {
                                              var item = ref.item
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "text-subtitle-1 font-weight-bold align-center",
                                                  },
                                                  [_vm._v(" Total ")]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.q_production",
                                            fn: function (ref) {
                                              var item = ref.item
                                              return [
                                                item.q_production
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-subtitle-2 font-weight-bold align-center",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.q_production.toLocaleString(
                                                              "en-US"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-subtitle-2 font-weight-bold align-center",
                                                      },
                                                      [_vm._v("0")]
                                                    ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.q_health",
                                            fn: function (ref) {
                                              var item = ref.item
                                              return [
                                                item.q_health
                                                  ? _c(
                                                      "v-progress-linear",
                                                      {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          value: item.q_health,
                                                          height: "15",
                                                          "background-color":
                                                            _vm.progressBackground(
                                                              item.q_health
                                                            ),
                                                          color:
                                                            _vm.progressBarColor(
                                                              item.q_health
                                                            ),
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-center",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                Math.round(
                                                                  item.q_health
                                                                ).toFixed(0)
                                                              ) + "%"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "v-progress-linear",
                                                      {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          value: 0,
                                                          height: "15",
                                                          "background-color":
                                                            _vm.progressBackground(
                                                              0
                                                            ),
                                                          color:
                                                            _vm.progressBarColor(
                                                              0
                                                            ),
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-center",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(0) + "%"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.q_projected",
                                            fn: function (ref) {
                                              var item = ref.item
                                              return [
                                                item.q_projected
                                                  ? _c(
                                                      "v-chip",
                                                      {
                                                        attrs: {
                                                          color: _vm.getColor(
                                                            item.q_projected
                                                          ),
                                                          dark: "",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              Math.round(
                                                                item.q_projected
                                                              ).toFixed(0)
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "v-chip",
                                                      {
                                                        attrs: {
                                                          color:
                                                            _vm.getColor(0),
                                                          dark: "",
                                                        },
                                                      },
                                                      [_vm._v(" 0 ")]
                                                    ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.q_downtime",
                                            fn: function (ref) {
                                              var item = ref.item
                                              return [
                                                _c(
                                                  "v-chip",
                                                  {
                                                    attrs: {
                                                      color:
                                                        _vm.transformDowntime(
                                                          item.q_downtime
                                                        ),
                                                      dark: "",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          Math.round(
                                                            item.q_downtime
                                                          ).toFixed(0)
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.year_production",
                                            fn: function (ref) {
                                              var item = ref.item
                                              return [
                                                item.year_production
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-subtitle-2 font-weight-bold align-center",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.year_production.toLocaleString(
                                                              "en-US"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-subtitle-2 font-weight-bold align-center",
                                                      },
                                                      [_vm._v("0")]
                                                    ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.year_health",
                                            fn: function (ref) {
                                              var item = ref.item
                                              return [
                                                item.year_health
                                                  ? _c(
                                                      "v-progress-linear",
                                                      {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          value:
                                                            item.year_health,
                                                          height: "15",
                                                          "background-color":
                                                            _vm.progressBackground(
                                                              item.year_health
                                                            ),
                                                          color:
                                                            _vm.progressBarColor(
                                                              item.year_health
                                                            ),
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-center",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                Math.round(
                                                                  item.year_health
                                                                ).toFixed(0)
                                                              ) + "%"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "v-progress-linear",
                                                      {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          value: 0,
                                                          height: "15",
                                                          "background-color":
                                                            _vm.progressBackground(
                                                              0
                                                            ),
                                                          color:
                                                            _vm.progressBarColor(
                                                              0
                                                            ),
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-center",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(0) + "%"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item.year_projected",
                                            fn: function (ref) {
                                              var item = ref.item
                                              return [
                                                item.year_projected
                                                  ? _c(
                                                      "v-chip",
                                                      {
                                                        attrs: {
                                                          color: _vm.getColor(
                                                            item.year_projected
                                                          ),
                                                          dark: "",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              Math.round(
                                                                item.year_projected
                                                              ).toFixed(0)
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "v-chip",
                                                      {
                                                        attrs: {
                                                          color:
                                                            _vm.getColor(0),
                                                          dark: "",
                                                        },
                                                      },
                                                      [_vm._v(" 0 ")]
                                                    ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }