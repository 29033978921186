<template>
  <div>
    <v-card>
      <v-row>
        <v-col cols="2" class="ml-4 mt-2">
          <v-img
            src="@/assets/img/logoSOV.png"
            class="navbar-brand-img ms-3"
            height="50px"
            width="120px"
          >
          </v-img>
        </v-col>
        <v-col cols="3">
          <v-toolbar flat>
            <v-toolbar-title>{{ this.siteGroupName }} Fleet Report</v-toolbar-title>
          </v-toolbar>
        </v-col>
        <v-col cols="3">
          <v-toolbar flat>
            <!-- fix this text -->
            <v-toolbar-title class="text-h5"><b class="text-h5 font-weight-bold">Q{{ this.previousQuarter }}</b> - ({{ this.displayDates }})</v-toolbar-title>
          </v-toolbar>
        </v-col>
      </v-row>
      <div>
        <v-tabs v-model="NewTab">
          <v-tab href="#chart_tab"> Fleet Report </v-tab>
          <v-tab href="#fleet_stats"> Individual Sites </v-tab>
          <v-tab href="#inverter_stats"> Inverter Stats </v-tab>
        </v-tabs>
        <v-tabs-items v-model="NewTab">
          <v-tab-item value="fleet_stats">
            <v-row>
              <v-col cols="12">
                <v-card class="card-shadow border-radius-xl h-100">
                  <v-card-text>
                    <v-data-table
                      ref="datatable"
                      :headers="quarterHeaders"
                      :items="sites"
                      @current-items="getFiltered"
                      class="table"
                      :page.sync="page"
                      hide-default-footer
                      @page-count="pageCount = $event"
                      :items-per-page="itemsPerPage"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-btn @click="exportToCsv" class="mt-12">
                            Export
                          </v-btn>
                        </v-toolbar>
                      </template>

                      <template v-slot:header.q_health="{ header }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <span v-on="on">{{ header.text }}</span>
                          </template>
                          <span>This is based on satellite imagery to estimate production based on the actual sunshine that fell on your array. Snow cover influences this significantly.</span>
                        </v-tooltip>
                      </template>

                      <template v-slot:header.q_projected="{ header }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <span v-on="on">{{ header.text }}</span>
                          </template>
                          <span>This is based on 30 year historical averages from PVWatts or customer provided monthly projections. Wide variation is normal if this period was far from average.</span>
                        </v-tooltip>
                      </template>

                      <template v-slot:header.notes="{ header }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <span v-on="on">{{ header.text }}</span>
                          </template>
                          <span>Cases open for this site at any point in this quarter.</span>
                        </v-tooltip>
                      </template>

                      <template v-slot:header.year_health="{ header }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <span v-on="on">{{ header.text }}</span>
                          </template>
                          <span>This is based on satellite imagery to estimate production based on the actual sunshine that fell on your array. Snow cover influences this significantly.</span>
                        </v-tooltip>
                      </template>

                      <template v-slot:header.year_projected="{ header }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <span v-on="on">{{ header.text }}</span>
                          </template>
                          <span>This is based on 30 year historical averages from PVWatts or customer provided monthly projections. Wide variation is normal if this period was far from average.</span>
                        </v-tooltip>
                      </template>

                      <template v-slot:item.site_name="{ item }">
                        <div
                          class="d-flex align-center ms-2"
                          @click="openWindow(item.url)"
                        >
                          <span
                            class="text-base font-weight-bold text-body"
                            style="
                              cursor: pointer;
                              color: blue;
                              text-decoration: underline
                            "
                          >
                            {{ item.site_name }}
                          </span>
                        </div>
                      </template>
                      <template v-slot:item.q_production="{ item }">
                        <div v-if="item.q_production">{{ item.q_production.toLocaleString("en-US") }}</div>
                        <div v-else>0</div> 
                      </template>
                      <template v-slot:item.q_health="{ item }">
                        <v-progress-linear v-if="item.q_health" :value="item.q_health"  style="width: 100px" height="15" :background-color="progressBackground(item.q_health)" :color="progressBarColor((item.q_health))" >
                          <div class="text-center">{{ Math.round(item.q_health).toFixed(0) }}%</div>
                        </v-progress-linear>
                        <v-progress-linear v-else :value="0"  style="width: 100px" height="15" :background-color="progressBackground(0)" :color="progressBarColor((0))" >
                          <div class="text-center">{{ 0 }}%</div>
                        </v-progress-linear>
                      </template>
                      <template v-slot:item.q_projected="{ item }">
                        <v-chip v-if="item.q_projected" :color="getColor(item.q_projected)" dark>
                          {{ Math.round(item.q_projected).toFixed(0) }}
                        </v-chip>
                        <v-chip v-else :color="getColor(0)" dark>
                          0
                        </v-chip>
                      </template>
                      <template v-slot:item.q_downtime="{ item }">
                        <v-chip :color="transformDowntime(item.q_downtime)" dark>
                          {{ Math.round(item.q_downtime).toFixed(0) }}
                        </v-chip>
                      </template>
                      <template v-slot:item.notes="{ item }">
                        <div>
                          {{ item.notes }}
                        </div>
                      </template>
                      <template v-slot:item.year_production="{ item }">
                        <div v-if="item.year_production">{{ item.year_production.toLocaleString("en-US") }}</div>
                        <div v-else>0</div> 
                      </template>
                      <template v-slot:item.year_health="{ item }">
                        <v-progress-linear v-if="item.year_health" :value="item.year_health"  style="width: 100px" height="15" :background-color="progressBackground(item.year_health)" :color="progressBarColor((item.year_health))" >
                          <div class="text-center">{{ Math.round(item.year_health).toFixed(0) }}%</div>
                        </v-progress-linear>
                        <v-progress-linear v-else :value="0"  style="width: 100px" height="15" :background-color="progressBackground(0)" :color="progressBarColor((0))" >
                          <div class="text-center">{{ 0 }}%</div>
                        </v-progress-linear>
                      </template>
                      <template v-slot:item.year_projected="{ item }">
                        <v-chip v-if="item.year_projected" :color="getColor(item.year_projected)" dark>
                          {{ Math.round(item.year_projected).toFixed(0) }}
                        </v-chip>
                        <v-chip v-else :color="getColor(0)" dark>
                          0
                        </v-chip>
                      </template>
                    </v-data-table>
                  </v-card-text>
                  <v-card-actions class="card-padding">
                    <v-row>
                      <v-col cols="6" lg="3" class="d-flex align-center">
                        <span class="text-body me-3 text-sm">Items per page:</span>
                        <v-text-field
                          hide-details
                          type="number"
                          outlined
                          min="-1"
                          max="15"
                          background-color="rgba(255,255,255,.9)"
                          color="rgba(0,0,0,.6)"
                          light
                          :value="itemsPerPage"
                          @input="updateItemsPerPage"
                          placeholder="Items per page"
                          class="font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="6" class="ml-auto d-flex justify-end">
                        <v-pagination
                          prev-icon="fa fa-angle-left"
                          next-icon="fa fa-angle-right"
                          class="pagination"
                          color="#D81B60"
                          v-model="page"
                          :length="pageCount"
                          circle
                        ></v-pagination>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item value="inverter_stats">
            <v-row>
              <v-col cols="6">
                <v-card class="mt-4">
                  <v-card-text class="text-subtitle-2 font-weight-bold">
                    This section displays each inverter's production (adjusted for size) compared to the median production of the other inverters in that specific site for each month of the current quarter.
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card class="mt-n8">
                  <v-card-text>
                    <v-data-table
                      ref="datatable"
                      :headers="inverterHeaders"
                      :items="sites"
                      :single-expand="singleExpand"
                      :expanded.sync="expandedIds"
                      item-key="id"
                      show-expand
                      class="table"
                      hide-default-footer
                      @current-items="getFiltered2"
                      :page.sync="page2"
                      @page-count="pageCount2 = $event"
                      :items-per-page="itemsPerPage2"
                      mobile-breakpoint="0"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-row>
                            <v-col cols="3">
                              <v-btn @click="expandAll()">Expand All</v-btn>
                            </v-col>
                          </v-row>
                        </v-toolbar>
                      </template>
                      <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                          <fleet-inverters
                            v-bind:site_name="item.site_name"
                            v-bind:inverterMetrics="inverter_metrics[item.site_name]"
                          ></fleet-inverters>
                        </td>
                      </template>
                    </v-data-table>
                  </v-card-text>
                  <v-card-actions class="card-padding">
                    <v-row>
                      <v-col cols="6" lg="3" class="d-flex align-center">
                        <span class="text-body me-3 text-sm">Items per page:</span>
                        <v-text-field
                          hide-details
                          type="number"
                          outlined
                          min="-1"
                          max="15"
                          background-color="rgba(255,255,255,.9)"
                          color="rgba(0,0,0,.6)"
                          light
                          :value="itemsPerPage2"
                          @input="updateItemsPerPage2"
                          placeholder="Items per page"
                          class="font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="6" class="ml-auto d-flex justify-end">
                        <v-pagination
                          prev-icon="fa fa-angle-left"
                          next-icon="fa fa-angle-right"
                          class="pagination"
                          color="#D81B60"
                          v-model="page2"
                          :length="pageCount2"
                          circle
                        ></v-pagination>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item value="chart_tab">
            <v-row>
              <v-col cols="12">
                <v-card class="card-shadow border-radius-xl h-100">
                  <div id="quarterly-stats">
                    <apexchart
                      height="400"
                      type="line"
                      :options="quarterlyOptions"
                      :series="series"
                    ></apexchart>
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card class="card-shadow border-radius-xl h-100">
                  <div>
                    <v-data-table
                      ref="datatable"
                      :headers="summaryHeaders"
                      :items="summary"
                      class="table"
                      hide-default-footer
                    >

                      <template v-slot:header.q_health="{ header }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <span v-on="on">{{ header.text }}</span>
                          </template>
                          <span>This is based on satellite imagery to estimate production based on the actual sunshine that fell on your array. Snow cover influences this significantly.</span>
                        </v-tooltip>
                      </template>

                      <template v-slot:header.q_projected="{ header }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <span v-on="on">{{ header.text }}</span>
                          </template>
                          <span>This is based on 30 year historical averages from PVWatts or customer provided monthly projections. Wide variation is normal if this period was far from average.</span>
                        </v-tooltip>
                      </template>

                      <template v-slot:header.year_health="{ header }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <span v-on="on">{{ header.text }}</span>
                          </template>
                          <span>This is based on satellite imagery to estimate production based on the actual sunshine that fell on your array. Snow cover influences this significantly.</span>
                        </v-tooltip>
                      </template>

                      <template v-slot:header.year_projected="{ header }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <span v-on="on">{{ header.text }}</span>
                          </template>
                          <span>This is based on 30 year historical averages from PVWatts or customer provided monthly projections. Wide variation is normal if this period was far from average.</span>
                        </v-tooltip>
                      </template>

                      <template v-slot:item.site_name="{ item }">
                        <div
                          class="text-subtitle-1 font-weight-bold align-center"
                        >
                          Total
                        </div>
                      </template>
                      <template v-slot:item.q_production="{ item }">
                        <div v-if="item.q_production" class="text-subtitle-2 font-weight-bold align-center">{{ item.q_production.toLocaleString("en-US") }}</div>
                        <div v-else class="text-subtitle-2 font-weight-bold align-center">0</div> 
                      </template>
                      <template v-slot:item.q_health="{ item }">
                        <v-progress-linear v-if="item.q_health" :value="item.q_health"  style="width: 100px" height="15" :background-color="progressBackground(item.q_health)" :color="progressBarColor((item.q_health))" >
                          <div class="text-center">{{ Math.round(item.q_health).toFixed(0) }}%</div>
                        </v-progress-linear>
                        <v-progress-linear v-else :value="0"  style="width: 100px" height="15" :background-color="progressBackground(0)" :color="progressBarColor((0))" >
                          <div class="text-center">{{ 0 }}%</div>
                        </v-progress-linear>
                      </template>
                      <template v-slot:item.q_projected="{ item }">
                        <v-chip v-if="item.q_projected" :color="getColor(item.q_projected)" dark>
                          {{ Math.round(item.q_projected).toFixed(0) }}
                        </v-chip>
                        <v-chip v-else :color="getColor(0)" dark>
                          0
                        </v-chip>
                      </template>
                      <template v-slot:item.q_downtime="{ item }">
                        <v-chip :color="transformDowntime(item.q_downtime)" dark>
                          {{ Math.round(item.q_downtime).toFixed(0) }}
                        </v-chip>
                      </template>
                      <template v-slot:item.year_production="{ item }">
                        <div v-if="item.year_production" class="text-subtitle-2 font-weight-bold align-center">{{ item.year_production.toLocaleString("en-US") }}</div>
                        <div v-else class="text-subtitle-2 font-weight-bold align-center">0</div> 
                      </template>
                      <template v-slot:item.year_health="{ item }">
                        <v-progress-linear v-if="item.year_health" :value="item.year_health"  style="width: 100px" height="15" :background-color="progressBackground(item.year_health)" :color="progressBarColor((item.year_health))" >
                          <div class="text-center">{{ Math.round(item.year_health).toFixed(0) }}%</div>
                        </v-progress-linear>
                        <v-progress-linear v-else :value="0"  style="width: 100px" height="15" :background-color="progressBackground(0)" :color="progressBarColor((0))" >
                          <div class="text-center">{{ 0 }}%</div>
                        </v-progress-linear>
                      </template>
                      <template v-slot:item.year_projected="{ item }">
                        <v-chip v-if="item.year_projected" :color="getColor(item.year_projected)" dark>
                          {{ Math.round(item.year_projected).toFixed(0) }}
                        </v-chip>
                        <v-chip v-else :color="getColor(0)" dark>
                          0
                        </v-chip>
                      </template>
                    </v-data-table>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-card>
  </div>
</template>
    
  <script>
import Service from "@/services/Service.js";
import RoundSlider from "vue-round-slider";
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import ReportCase from "@/components/ReportCase";
import FleetInverters from "./FleetInverters.vue";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

export default {
  components: {
    //   ComparativeEnergy,
    RoundSlider,
    ReportCase,
    FleetInverters,
  },
  props: ["site_id", "nativeLink", "system", "plans"],
  data() {
    return {
      page: 1,
      pageCount: 0,
      page2: 1,
      pageCount2: 0,
      itemsPerPage: 10,
      itemsPerPage2: 10,
      singleExpand: false,
      tab: null,
      NewTab: null,
      sliderValue: "",
      kwhSelect: true,
      kwhKwpSelect: false,
      performanceSelect: false,
      estimateSelect: true,
      reportDialog: false,
      reportDatesRange: [],
      selected: null,
      selectedStep: null,
      selectedYear: null,
      selectedMonth: null,
      selectedQuarter: null,
      selectedQuarterYear: null,
      selectedUploadKwhYear: null,
      selectedUploadKwhMonth: null,
      selectedUploadKwhAmount: null,
      previousQuarter: null,
      selectedTab: "quarter",
      siteName: null,
      expander: false,
      expandedIds: [],
      siteId: null,
      thirtyDayHealth: null,
      ninetyDayHealth: null,
      yearHealth: null,
      quarterSavings: null,
      yearSavings: null,
      cases: [],
      currentItems: [],
      currentQuarter: null,
      dateStart: null,
      dateEnd: null,
      siteGroupName: null,
      combinedData: [],
      sites: [],
      summary: [],
      inverter_metrics: null,
      colors: [],
      quarterHeaders: [
      {
          text: "Site Name",
          align: "start",
          cellClass: "border-bottom",
          value: "site_name",
          class: "text-subtitle-2 font-weight-bold",
        },
        {
          text: "Production (KWH)",
          value: "q_production",
          class: "text-subtitle-2 font-weight-bold",
        },
        {
          text: "Estimated Production System Health %",
          value: "q_health",
          class: "text-subtitle-2 font-weight-bold",
        },
        {
          text: "Projected Energy System Health %",
          value: "q_projected",
          class: "text-subtitle-2 font-weight-bold",
        },
        {
          text: "System Uptime %",
          value: "q_downtime",
          class: "text-subtitle-2 font-weight-bold",
        },
        {
          text: "Cases",
          value: "notes",
          class: "text-subtitle-2 font-weight-bold",
        },
        {
          text: "Last 12 Months Production (KWH)",
          value: "year_production",
          class: "text-subtitle-2 font-weight-bold",
        },
        {
          text: "Last 12 Months Estimated Production System Health %",
          value: "year_health",
          class: "text-subtitle-2 font-weight-bold",
        },
        {
          text: "Last 12 Months Projected Energy System Health %",
          value: "year_projected",
          class: "text-subtitle-2 font-weight-bold",
        },
      ],
      summaryHeaders: [
        {
          text: "Site Name",
          align: "start",
          cellClass: "border-bottom",
          value: "site_name",
          class: "text-subtitle-2 font-weight-bold",
        },
        {
          text: "Production (KWH)",
          value: "q_production",
          class: "text-subtitle-2 font-weight-bold",
        },
        {
          text: "Estimated Production System Health %",
          value: "q_health",
          class: "text-subtitle-2 font-weight-bold",
        },
        {
          text: "Projected Energy System Health %",
          value: "q_projected",
          class: "text-subtitle-2 font-weight-bold",
        },
        {
          text: "System Uptime %",
          value: "q_downtime",
          class: "text-subtitle-2 font-weight-bold",
        },
        {
          text: "Last 12 Months Production (KWH)",
          value: "year_production",
          class: "text-subtitle-2 font-weight-bold",
        },
        {
          text: "Last 12 Months Estimated Production System Health %",
          value: "year_health",
          class: "text-subtitle-2 font-weight-bold",
        },
        {
          text: "Last 12 Months Projected Energy System Health %",
          value: "year_projected",
          class: "text-subtitle-2 font-weight-bold",
        },
      ],
      inverterHeaders: [
        {
          text: "Site Name",
          align: "start",
          cellClass: "border-bottom",
          value: "site_name",
          class:
            "text-subtitle-2 font-weight-bold",
        },
      ],
      quarterlyOptions: {
        chart: {
          id: "quarterly-stats",
          height: 600,
        },
        xaxis: {
          categories: [
            "Q1",
            "Q2",
            "Q3",
            "Q4",
          ]
        }
      },
      rules: [(value) => !!value || "Required."],
    };
  },
  computed: {
    // pages() {
    //   return this.pagination.rowsPerPage
    //     ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
    //     : 0;
    // },
    //somehow get pages to be different between inverter table and sites info
    displayDates() {
      return this.startDate + ' - ' + this.endDate
    }
  },
  updated() {
    var title = this.siteGroupName + " Report";
    document.title = title;
  },
  created: function () {
    let d = new Date();
    this.getPreviousQuarterStartDate(d);
    this.decodeLink();
    // this.getCases();
    this.getData();
  },
  methods: {
    //exporting functions
    expandPage() {
      this.itemsPerPage = -1;
    },
    closeSitesCSVExport() {
      this.reportDialog = false;
      this.reportDatesRange = [];
      this.itemsPerPage = 10;
    },
    clearSitesCSVExport() {
      this.reportDatesRange = [];
    },
    formatReportDatesRange() {
      if (this.reportDatesRange.length === 2) {
        const [from, until] = this.reportDatesRange;
        return `From ${from}, until ${until}`;
      } else {
        return "";
      }
    },
    exportToCsv() {
      const headers = this.quarterHeaders.map(header => header.text);
  
      // Extract data from the v-data-table
      const data = this.sites.map(item => [item.site_name, item.q_production, item.q_health, item.q_projected, item.q_downtime, item.notes, item.year_production, item.year_health, item.year_projected]);

      // Combine headers and data into a CSV string
      const csvData = [headers, ...data].map(row => row.map(item => `"${item}"`).join(','));

      // const csvData = [headers, ...data].map(row => row.join(','));

      // Create a CSV file as a Blob object
      const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });

      // Create a URL for the Blob object
      const csvUrl = URL.createObjectURL(csvBlob);

      // Create an anchor tag for the download link
      const link = document.createElement('a');
      link.href = csvUrl;
      link.download = 'FleetView.csv';

      // Trigger the download
      link.click();

      // Clean up the URL object
      URL.revokeObjectURL(csvUrl);
    },
    getPreviousQuarterStartDate(date) {
      const month = date.getMonth();
      const year = date.getFullYear();

      // Determine the starting month of the previous quarter
      let previousQuarterStartMonth;
      let previousQuarterEndMonth;
      if (month <= 2) {
        previousQuarterStartMonth = 10;
        previousQuarterEndMonth = 12 + '/31/';
        this.previousQuarter = 4;
      } else if (month <= 5) {
        previousQuarterStartMonth = 1;
        previousQuarterEndMonth = 3 + '/31/';
        this.previousQuarter = 1;
      } else if (month <= 8) {
        previousQuarterStartMonth = 4;
        previousQuarterEndMonth = 6 + '/30/';
        this.previousQuarter = 2;
      } else {
        previousQuarterStartMonth = 7;
        previousQuarterEndMonth = 9 + '/30/';
        this.previousQuarter = 3;
      }

      // Determine the year of the previous quarter
      let previousQuarterStartYear;
      if (previousQuarterStartMonth === 10) {
        previousQuarterStartYear = year - 1;
      } else {
        previousQuarterStartYear = year;
      }

      // Return a new Date object for the start of the previous quarter
      this.startDate = previousQuarterStartMonth + '/1/' + previousQuarterStartYear;
      this.endDate = previousQuarterEndMonth + previousQuarterStartYear;

      // Return a new Date object for the start of the previous quarter
      this.selectedMonth = previousQuarterStartMonth
      this.selectedYear = previousQuarterStartYear
    },
    getColor(percentage) {
      if (percentage < 75) {
        return "red";
      } else if (percentage < 90) {
        return "orange";
      } else {
        return "green";
      }
    },
    progressBarColor(percentage) {
      let color = "";

      if (percentage < 75) {
        color = "red";
      } else if (percentage < 90) {
        color = "orange";
      } else {
        color = "green";
      }

      return color;
    },
    progressBackground(percentage) {
      if(percentage <= 0) {
        return "red";
      } else {
        return "grey lighten-2";
      }
    },
    transformDowntime(uptime) {
      if (uptime == null) {
        uptime = 0;
      }
      let color = this.getColor(uptime)
      return color
    },
    updateItemsPerPage(value) {
      const numValue = parseInt(value, 10);
      if (!isNaN(numValue)) {
        this.itemsPerPage = numValue;
        this.page = 1; // reset page number when changing items per page
      }
    },
    updateItemsPerPage2(value) {
      const numValue = parseInt(value, 10);
      if (!isNaN(numValue)) {
        this.itemsPerPage2 = numValue;
        this.page2 = 1; // reset page number when changing items per page
      }
    },
    selectChartTab() {
      this.loadChartData();
    },
    getData() {
      this.loadChartData();
      this.getQuarterandYearStats();
      this.getInverterStats();
      this.getName();
    },
    expandAll() {
      this.expander = !this.expander;
      if(this.expander) {
          this.expandedIds = this.currentInverterItems
      } else {
          this.expandedIds = []
      }
    },
    getName() {
      Service.getAllRptSiteGroupsFV(this.token)
        .then((response) => {
          let site_groups = response.data;
          const foundObject = site_groups.find(obj => obj.id === this.site_group_id);
          this.siteGroupName = foundObject.name;
        })
        .catch((error) => {
          NProgress.done();
          console.log(error);
        });
    },
    getInverterStats() {
      NProgress.start();
      Service.getFleetQtrInverterStats(this.site_group_id, this.token, this.selectedMonth, this.selectedYear)
        .then((response) => {
          this.inverter_metrics = response.data;
          NProgress.done();
        })
        .catch((error) => {
          NProgress.done();
          console.log(error);
        });
    },
    decodeLink() {
      var getSegment = function (url, index) {
        return url.replace(/^https?:\/\//, "").split("?")[index];
      };
      var url = getSegment(window.location.href, 1);
      var newURL = url.slice(14);
      var final = newURL.split("&");
      this.site_group_id = parseInt(final[0]);
      this.token = final[1].slice(6);
    },
    getQuarterandYearStats() {
      NProgress.start();
      // here put actual call
      Service.getFleetStats(this.site_group_id, this.token, this.selectedMonth, this.selectedYear)
        .then((response) => {
          const all = response.data;
          all.forEach((site, index) => {
            all[index].q_downtime = 100 - site.q_downtime;
          });
          this.sites = all.slice(0, -1);
          this.summary = [all[all.length - 1]];
          console.log(this.summary)
          // NProgress.done();
        })
        .catch((error) => {
          NProgress.done();
          console.log(error);
        });
    },
    getFiltered(e) {
      this.currentItems = e;
    },
    getFiltered2(e) {
      this.currentInverterItems = e;
    },
    openWindow: function (link) {
      window.open(link, "_blank");
    },
    getYearColors() {
      let colors = ['#F9CE1D', '#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0'];
      let years = [];
      for (let i = this.selectedYear - 5; i <= this.selectedYear; i++) {
        years.push(i);
      }
      let yearColors = {};
      years.forEach((year, index) => {
        yearColors[year] = colors[index];
      });
      console.log(yearColors)
      return yearColors
    },
    loadChartData() {
      NProgress.start();
      Service.getFleetQtrkWhSum(this.site_group_id, this.token, this.selectedMonth, this.selectedYear)
        .then((response) => {
          let allYears = Object.values(response.data);
          let yearlyData = allYears[allYears.length - 1];
          let s_comp = allYears.slice(0, -1);

          var siteCompEnergy = s_comp;

          this.quarterlyOptions = {
            chart: {
              id: "quarterly-stats",
            },
            xaxis: {
              categories: ['Q1', 'Q2', 'Q3', 'Q4']
            },
          };
          let kwhData = yearlyData.map((d) => {
            return parseFloat(d.kwh).toFixed(0);
          });
          // let displayYear = yearlyData[0].year
          
          let x = this.renderNewSeries(kwhData);

          for (let i = 0; i < siteCompEnergy.length; i++) {
            let kwhData = siteCompEnergy[i].map((k) => {
              return k.kwh === null ? 0 : parseFloat(k.kwh).toFixed(0);
            });
            x.push({
              name: `${siteCompEnergy[i][0].year}`,
              type: "line",
              data: kwhData,
            });
          }
          this.series = x;
          
        })
        .catch((error) => {
          NProgress.done();
          console.log(error);
        });
    },
    // maybe how we get the cases??
    // getCases() {
    //   this.sites.forEach((site) => {
    //     Service.getCasesandSitesSc(site.id, this.token)
    //       .then((response) => {
    //         this.cases.push(response[0].data);
    //         // this.siteName = response[1].data.name;
    //         // this.loadCustomerMetrics();
    //       })
    //       .catch((error) => {
    //         console.log("Error getting cases data:", error.response);
    //       });
    //   });
    // },
    renderNewSeries(kwhData, year) {
      return [
        {
          name: "kWh",
          type: "column",
          data: kwhData,
        },
      ];
    },
    getMonthFromString(mon) {
      return new Date(Date.parse(mon + " 1, 2012")).getMonth() + 1;
    },
    getMonthStrFromInt(m) {
      return this.months[m];
    },
  },
};
</script>
    
<style>
</style>
    