import { render, staticRenderFns } from "./ReportCase.vue?vue&type=template&id=4d58c2b4&"
import script from "./ReportCase.vue?vue&type=script&lang=js&"
export * from "./ReportCase.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VDataTable,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4d58c2b4')) {
      api.createRecord('4d58c2b4', component.options)
    } else {
      api.reload('4d58c2b4', component.options)
    }
    module.hot.accept("./ReportCase.vue?vue&type=template&id=4d58c2b4&", function () {
      api.rerender('4d58c2b4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ReportCase.vue"
export default component.exports