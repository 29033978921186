var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    attrs: {
      headers: _vm.columns,
      items: _vm.sitecases,
      "items-per-page": 10,
      "custom-sort": _vm.customSort,
    },
    scopedSlots: _vm._u([
      {
        key: "item.notes",
        fn: function (ref) {
          var item = ref.item
          return [
            _c(
              "td",
              _vm._l(item.notes, function (n) {
                return _c("div", { staticClass: "report-flagged" }, [
                  n.report_flag ? _c("p", [_vm._v(_vm._s(n.note))]) : _vm._e(),
                ])
              }),
              0
            ),
          ]
        },
      },
      {
        key: "item.downtime_pct_prev_qtr",
        fn: function (ref) {
          var item = ref.item
          return [
            _c("div", [
              _vm._v(
                " " + _vm._s(item.downtime_pct_prev_qtr.toFixed(1)) + "% "
              ),
            ]),
          ]
        },
      },
      {
        key: "header.downtime_pct_prev_qtr",
        fn: function (ref) {
          var header = ref.header
          return [
            _c(
              "v-tooltip",
              {
                attrs: { top: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        return [
                          _c("span", _vm._g({}, on), [
                            _vm._v(_vm._s(header.text)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              },
              [
                _c("span", [
                  _vm._v(
                    "This is the downtime for the quarter(s) in which the case was open."
                  ),
                ]),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }