<template>
  <v-data-table
    :headers="headers"
    :items="inverters"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    :items-per-page="itemsPerPage"
    hide-default-footer
    class="table"
    mobile-breakpoint="0"
  >
    

    <template v-slot:item.inverter_name="{ item }">
      <div class="d-flex align-center ms-2">
        <span class="text-sm font-weight-normal text-body">
          {{ item.inverter_name }}
        </span>
      </div>
    </template>
    <!-- month1 -->
    <template v-slot:header.q1="{ header }">
      <div class="text-subtitle-2 font-weight-bold">{{ month1 }}</div>
    </template>
    <template v-slot:item.q1="{ item }">
      <v-progress-linear :value="item.q1"  style="width: 100px" height="15" :background-color="progressBackground(item.q1)" :color="progressBarColor((item.q1))" >
          <div class="text-center">{{ Math.ceil(item.q1) }}%</div>
        </v-progress-linear>
    </template>
    <!-- month2 -->
    <template v-slot:header.q2="{ header }">
      <div class="text-subtitle-2 font-weight-bold">{{ month2 }}</div>
    </template>
    <template v-slot:item.q2="{ item }">
      <v-progress-linear :value="item.q2"  style="width: 100px" height="15" :background-color="progressBackground(item.q2)" :color="progressBarColor((item.q2))" >
          <div class="text-center">{{ Math.ceil(item.q2) }}%</div>
        </v-progress-linear>
    </template>
    <template v-slot:header.q3="{ header }">
      <div class="text-subtitle-2 font-weight-bold">{{ month3 }}</div>
    </template>
    <template v-slot:item.q3="{ item }">
      <v-progress-linear :value="item.q3"  style="width: 100px" height="15" :background-color="progressBackground(item.q3)" :color="progressBarColor((item.q3))" >
          <div class="text-center">{{ Math.ceil(item.q3) }}%</div>
        </v-progress-linear>
    </template>
  </v-data-table>
</template>
<script>

export default {
  props: [
    'inverterMetrics',
    'site_name'
  ],
  name: "data-table-inverters",
  data () {
    return {
      sortBy: 'day_health',
      sortDesc: false,
      itemsPerPage: -1,
      headers: [
        {
          text: "Inverter Name",
          align: "start",
          cellClass: "border-bottom",
          value: "inverter_name",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: `January`,
          value: "q1",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "February",
          value: "q2",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "March",
          value: "q3",
          class: "text-secondary font-weight-bolder opacity-7",
        },
      ]
    }
  },
  methods: {
    getMonthName(month1) {
      if (month1) {
        const [year, month, day] = month1.split('-');
        const date = new Date(year, month - 1, day);
        return date.toLocaleString('default', { month: 'long' });
      } else {
        return "";
      }
    },
    progressBarColor(percentage) {
      let color = "";

      if (percentage < 75) {
        color = "red";
      } else if (percentage < 90) {
        color = "orange";
      } else {
        color = "green";
      }

      return color;
    },
    progressBackground(percentage) {
      if(percentage <= 0) {
        return "red";
      } else {
        return "grey lighten-2";
      }
    },
    getColor (percentage) {
      if (percentage < 75) {
        return 'red';
      } else if (percentage < 90) {
        return 'orange';
      } else {
        return 'green';
      }
    },
    getMonthName2(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString('default', { month: 'long' });
    },
    extractMonthNames(data) {
      const monthSet = new Set();
      for (let inverterData of Object.values(data)) {
          for (let dateStr of Object.keys(inverterData)) {
              monthSet.add(this.getMonthName(dateStr));
          }
      }
      return Array.from(monthSet);
    },
  },
  watch: {

  },

  computed: {
    inverters: function() {
      if(this.inverterMetrics) {
        const flattenedData = [];
        //Object.keys(this.inverterMetrics).forEach(siteName => {
          Object.keys(this.inverterMetrics).forEach(inverterName => {
            const inverterData = { inverter_name: inverterName, site_name: this.site_name };
            let i = 1;
            Object.keys(this.inverterMetrics[inverterName]).forEach(date => {
              const qKey = "q" + i;
              inverterData[qKey] = this.inverterMetrics[inverterName][date].q;
              i++;
            });
            flattenedData.push(inverterData);
          });
        //});

        // const x = flattenedData.filter((i) => {
        //   return i.site_name == this.site_name;
        // })
        return flattenedData;
      } else {
        return [];
      }
    },
    month1: function() {
      let month1_num;
      let month1 = "";
      // if(this.inverterMetrics) {
      //   Object.keys(this.inverterMetrics).some(siteName => {
      //     return Object.keys(this.inverterMetrics[siteName]).some(inverterName => {
      //       const dateKeys = Object.keys(this.inverterMetrics[siteName][inverterName]);
      //       if (dateKeys.length >= 1) {
      //         month1_num = dateKeys[0];
      //         return true;
      //       }
      //     });
      //   });
      //   month1 = this.getMonthName(month1_num);
      // } 
      if(this.inverterMetrics) {
        let months = this.extractMonthNames(this.inverterMetrics);
        if(months.length > 0) {
          month1 = months[0];
        }
      }
      return month1;
    },
    month2: function() {
      let month2_num;
      let month2 = "";
      if(this.inverterMetrics) {
        // Object.keys(this.inverterMetrics).some(siteName => {
        //   return Object.keys(this.inverterMetrics[siteName]).some(inverterName => {
        //     const dateKeys = Object.keys(this.inverterMetrics[siteName][inverterName]);
        //     if (dateKeys.length >= 1) {
        //       month2_num = dateKeys[1];
        //       return true;
        //     }
        //   });
        // });
        // month2 = this.getMonthName(month2_num);
        
        let months = this.extractMonthNames(this.inverterMetrics);
        if(months.length > 1) {
          month2 = months[1];
        }
      
      }
      return month2;
    },
    month3: function() {
      let month3_num;
      let month3 = "";
      if(this.inverterMetrics) {
        // Object.keys(this.inverterMetrics).some(siteName => {
        //   return Object.keys(this.inverterMetrics[siteName]).some(inverterName => {
        //     const dateKeys = Object.keys(this.inverterMetrics[siteName][inverterName]);
        //     if (dateKeys.length >= 1) {
        //       month3_num = dateKeys[2];
        //       return true;
        //     }
        //   });
        // });
        // month3 = this.getMonthName(month3_num);
        let months = this.extractMonthNames(this.inverterMetrics);
        if(months.length > 2) {
          month3 = months[2];
        }
      }
      return month3;
    }
  },
};
</script>
