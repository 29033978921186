<template>
  <v-data-table 
      :headers="columns"
      :items="sitecases"
      :items-per-page="10"
      :custom-sort="customSort"
  >
    <template v-slot:item.notes="{ item }">
      <td>
        <div class="report-flagged" v-for="n in item.notes">
          <p v-if="n.report_flag">{{ n.note }}</p>
        </div>
      </td>
    </template>
    <template v-slot:item.downtime_pct_prev_qtr="{ item }">
      <div>
        {{ item.downtime_pct_prev_qtr.toFixed(1) }}%
      </div>
    </template>
    <template v-slot:header.downtime_pct_prev_qtr="{ header }">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <span v-on="on">{{ header.text }}</span>
        </template>
        <span>This is the downtime for the quarter(s) in which the case was open.</span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>
  
<script>
import { Grid } from "@progress/kendo-vue-grid";

export default {
  props: {
    sitecases: Array,
    quarter: Number,
  },
  components: {
    grid: Grid,
  },
  data: function () {
    return {
      allNotes: null,
      columns: [],
      items: this._props.sitecases,
      newItems: null,
      sortBy: "date_added",
      sortDesc: true,
      isFirstSort: true,
      // sort: [{ field: "date_added", dir: "asc" }], // desc? is it working?
    };
  },
  methods: {
    setDates() {
      var d = new Date();
      this.date = d;
      this.currentMonth = d.getMonth();
    },
    setNotes() {
        let notes = this.items.map((item) => item.notes)
        this.allNotes = notes
    },
    customSort(items) {
      if (this.sortBy === "date_added") {
        this.sortDesc = !this.sortDesc;
        return items.sort((a, b) => {
          const dateA = new Date(a.date_added);
          const dateB = new Date(b.date_added);
          return this.sortDesc ? dateA - dateB : dateB - dateA;
        });
      } else {
        // Handle other columns' sorting
        // ...
      }
    },
  },
  created: function () {
    this.setNotes();
    // this.sortByDate();
    console.log(this._props.sitecases)
    this.columns = [
      { value: "subject", text: "Subject" },
      { value: "notes", text: "Notes" },
      { value: "date_added", text: "Date Created", sortable: true },
      { value: "date_closed", text: "Date Closed", sortable: true },
      { value: "downtime_pct_prev_qtr", text: "Case Downtime"},
    ] 
  },
  computed: {
    reportFlagged: function () {
      return this.allNotes.filter(function (n) {
        return n.report_flag
      })
    },
    computedQuarter() {
      return this._props.quarter
    },
  },
};
</script>
  